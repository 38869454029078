<template>
  <div class="page">
    <div class="page-header">
      <a-form-model
        class="ant-advanced-search-form"
        :labelCol="{span: 8}"
        :wrapperCol="{span: 16}"
        :form="form" @submit="handleSearch">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-input-group compact  style="margin-top: 6px;">
                <a-select  style="width: 110px;" v-model="selectedOption" @select="handleChange" default-value="mobileNo">
                    <a-select-option  value="mobileNo">
                      手机号
                    </a-select-option>
                    <a-select-option value="name">
                      姓名
                    </a-select-option>
                    <a-select-option value="idNo">
                      身份证号
                    </a-select-option>
                    <a-select-option value="repayNo">
                      还款流水号
                    </a-select-option>
                    <a-select-option value="channelRepayNo">
                      渠道订单号
                    </a-select-option>
                </a-select>
                <a-input style="width: 61%"  @input="handleInput"  :placeholder="inputPlaceholder" v-model="inputValue"/>
            </a-input-group>
          </a-col>
          <a-col :span="6">
             <a-form-model-item label="渠道方编号:" prop="channelCode">
              <a-select placeholder="请选择" v-model="form.channelCode" :allowClear=true  >
                  <a-select-option v-for="(item) in channelCodeList" :key="item">
                  {{  item }}
                  </a-select-option>
              </a-select>
             </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="资产方编号:" prop="fundCode">
              <a-select placeholder="请选择" v-model="form.fundCode" :allowClear=true  >
                  <a-select-option v-for="(item ) in fundCodeList" :key="item">
                    {{ item }}
                  </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col  :span="6">
            <a-form-model-item label="还款状态:" prop="repayStatus">
                <a-select placeholder="请选择" v-model="form.repayStatus" :allowClear=true>
                      <a-select-option v-for="(item) in statusList" :key="item.key">
                      {{  item.value}}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col  :span="6">
            <a-form-model-item label="还款时间:" prop="applyTime">
              <a-date-picker  placeholder="请选择" v-model="form.applyTime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"  />
            </a-form-model-item>
          </a-col>
          <a-col :span="18" style="text-align: right;" >
              <a-button type="primary" html-type="submit" style="margin-right: 10px;">
                搜索
              </a-button>
              <a-button  html-type="submit" @click="handleReset">
                清空
              </a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <a-row class="page-content">
      <a-col :span="24">
        <a-table
          :scroll="{ x: 2500 }"
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          :loading="loading"
          :row-key="record => record.id"
          @change="getPage"
        >
        <span slot="repayStatus" slot-scope="text">{{ text ==='SUCCESS' ? '成功' :  text ==='FAIL' ? '失败' : '还款中' }}</span>
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { repayOrderListQuery } from '@/api/repay';

const columns = [
  {
    title: '订单号',
    dataIndex: 'orderNo',
    key: 'orderNo',
  },
  {
    title: '还款号',
    dataIndex: 'repayNo',
    key: 'repayNo',
  },
  {
    title: '渠道方编号',
    dataIndex: 'channelCode',
    key: 'channelCode',
  },
  {
    title: '渠道方还款号',
    dataIndex: 'channelRepayNo',
    key: 'channelRepayNo',
  },
  {
    title: '还款状态',
    dataIndex: 'repayStatus',
    key: 'repayStatus',
    scopedSlots: { customRender: 'repayStatus' },
  },
  {
    title: '失败原因',
    key: 'failReason',
    dataIndex: 'failReason',
    ellipisis: true,
  },
  {
    title: '资金方编码',
    key: 'fundCode',
    dataIndex: 'fundCode',
  },
  {
    title: '资金方还款号',
    key: 'fundRepayNo',
    dataIndex: 'fundRepayNo',
  },
  {
    title: '还款时间',
    key: 'repayDate',
    dataIndex: 'repayDate',
  },
  {
    title: '手机号',
    key: 'mobileNo',
    dataIndex: 'mobileNo',
  },
  {
    title: '身份证号',
    key: 'idNo',
    dataIndex: 'idNo',
  },
  {
    title: '姓名',
    key: 'name',
    dataIndex: 'name',
    width: 100,
  },
  {
    title: '还款金额',
    key: 'repayTotalAmountStr',
    dataIndex: 'repayTotalAmountStr',
  },
  {
    title: '开始期数',
    key: 'periodStart',
    dataIndex: 'periodStart',
  },
  {
    title: '结束期数',
    key: 'periodEnd',
    dataIndex: 'periodEnd',
  },
];

export default {
  name: 'Home',
  data() {
    return {
      channelCodeList: ['XINRENPAI'],
      fundCodeList: ['NIWODAI'],
      statusList: [{ key: 'SUCCESS', value: '成功' }, { key: 'FAIL', value: '失败' }, { key: 'APPLYING', value: '还款中' }],
      selectedOption: 'mobileNo',
      inputValue: '',
      inputPlaceholder: '请输入手机号',
      data: [],
      columns,
      expand: false,
      loading: false,
      form: {
        mobileNo: '',
        name: '',
        idNo: '',
        repayNo: '',
        channelRepayNo: '',
        applyTime: '',
        channelCode: undefined,
        fundCode: undefined,
        repayStatus: undefined,
      },
      pagination: {
        current: 1,
        total: 0,
      },
    };
  },
  methods: {
    handleInput(event) {
      this.inputValue = event.target.value.trim();
    },
    handleReset() {
      this.inputValue = '';
      this.form = {
        mobileNo: '',
        name: '',
        idNo: '',
        repayNo: '',
        channelRepayNo: '',
        applyTime: '',
        channelCode: undefined,
        fundCode: undefined,
        repayStatus: undefined,
      };
      this.fetchPage();
    },
    handleChange(value) {
      this.inputValue = '';
      this.form.name = '';
      this.form.idNo = '';
      this.form.repayNo = '';
      this.form.channelRepayNo = '';
      this.form.mobileNo = '';
      this.inputPlaceholder = {
        name: '请输入姓名',
        idNo: '请输入身份证号',
        repayNo: '请输入还款流水号',
        channelRepayNo: '请输入渠道方订单号',
        mobileNo: '请输入手机号',
      }[value];
    },
    handleSearch(e) {
      e.preventDefault();
      const selectedValue = this.selectedOption;
      const formKey = {
        name: 'name',
        idNo: 'idNo',
        repayNo: 'repayNo',
        channelRepayNo: 'channelRepayNo',
        mobileNo: 'mobileNo',
      }[selectedValue];
      this.form[formKey] = this.inputValue;
      this.pagination.current = 1;
      this.fetchPage();
    },

    async fetchPage() {
      this.loading = true;
      const { result, success, errorMsg } = await repayOrderListQuery({
        ...this.form,
        limit: 10,
        offset: this.pagination.current,
      });
      this.loading = false;
      if (!success) {
        this.$message.error(errorMsg);
        return;
      }
      this.data = result.repayOrderFlowList;
      this.pagination.total = result.total;
    },
    getPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchPage();
    },
  },
  mounted() {
    this.fetchPage();
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  background-color: #fff;
  margin-bottom: 30px;
  padding: 30px 20px;
}

.page-content {
  background-color: #fff;
  padding: 16px 12px 0;
}
</style>
